export const lang = {
    es: {
			chooseYourLanguage: 'Elige tu idioma',
	        login: 'Iniciar sesión',
	        startLeaning: 'Comienza a aprender',
	        myProfile: 'Mi perfil',
			focus: 'Prueba de concentración',
	        smartbooks: 'Libros inteligentes',
	        logOut: 'Cerrar sesión',
        	pleaseWait: 'Por favor espera...',
        	pleaseWaitLoading: 'Por favor espera. Cargando...',
        	mentalDevelopment: 'Mental development for your children',
        	investInYour: "Invest in your child's potential now",
        	register: 'Register',
        	haveAlready: 'Have already an account? ',
        	howmuchDoes: 'How much does it cost?',
        	basic: 'BASIC',
        	freeLevels: '12 free levels',
        	homeworksPerLevel: '10 homeworks per level',
        	groupLessons: 'group lessons',
        	perMonth: 'per month',
        	tryNow: 'TRY NOW',
        	premium: 'PREMIUM',
        	additionalLevels: '+10 additional levels',
        	moreHomework: '+10 more homework',
        	customProfilePic: '+10 custom profile pic',
        	structureOfProgram: 'Structure of the program',
        	enrollmentInOurProgram: 'Enrollment in our program does not require any specific qualification or skill, only your child must be between 5 and 14 years old.',
        	age: 'Edad',
        	packedWithDesign: 'Packed with design features you already love plus unique',
        	learnMore: 'Learn more',
        	inviteYourFriends: 'Invite your friends',
        	sent: 'Envíar',
        	submit: 'Envíar',
        	username: 'Usuario',
        	password: 'Contraseña',
        	forgotPassword: '¿Olvidaste tu contraseña?',
        	resetYourPassword: 'Resetea tu contraseña',
        	newPassword: 'Nueva contraseña ',
        	confirmPassword: 'Confirma contraseña',
        	dashboard: 'Tablero',
        	accountSettings: 'Configuración',
        	membership: 'Membresía',
        	messages: 'Mensajes',
        	hallOfFame: 'Salón de la fama',
        	userProfile: 'Perfil',
        	ageBracket: 'Age Bracket',
        	grade: 'Grade',
        	country: 'País',
        	city: 'Ciudad',
        	parentsName: "Nombre de los padres",
        	phone: 'Teléfono',
        	email: 'Correo',
        	timeToStart: '¡Hora de comenzar!',
        	welcome: 'Bienvenido',
        	friend: 'Amigo',
        	levels: 'Niveles',
        	classroom: 'Salón de clases',
        	homework: 'Tarea',
        	matchRoom: 'Cuarto de retos',
        	nextClasses: 'Próximas clases',
        	ratingOfPlayers: 'Posiciones de los estudiantes',
        	showBy: 'SHOW BY',
        	interCupFrom: 'Puedes inscribirte a un Campeonato Internacional cuando acabes',
        	interCertiFrom: 'Puedes obtener un Certificado  Internacional cuando acabes',
        	level: 'Nivel',
        	todayPoints: 'Tu puntuación de hoy',
        	todaySteps: 'Avance del hoy',
        	completedStep: 'Sección terminada',
        	nextStepIs: 'La próxima sección es',
        	day: 'Día',
        	youAreHere: 'Estás aquí',
        	finishedAt: 'Terminaste el',
        	pending: 'Pendiente',
        	youAreAt: 'Estás en',
        	yourClassEnded: 'La clase ha terminado',
        	lessonEnded: 'La lección ha terminado',
        	lessonStarted: 'La lección ha comenzado',
        	lessonStartedSoon: 'Está lección comenzará pronto',
        	lessonNotStarted: 'La lección no ha comenzado. Por favor espera.',
        	joinVideoMeeting: 'Únete a la clase',
        	close: 'Cerrar',
        	levelPoints: 'Calificación promedio de nivel:',
        	lesson: 'Lección',
        	of: 'of',
        	complete: 'Completar',
        	static: 'Estáticas',
        	audible: 'Dictadas',
        	flash: 'Flash',
        	rememberChoose: 'ELIGE',
        	traningToPractice: 'Entenamiento para practicar',
        	testToLevel: 'Tarea para avanzar',
        	listenAndAnswer: 'Escucha y responde',
        	contragulations: 'Felicidades',
        	failed: 'Falla',
        	tryAgainPlease: 'Intenta de nuevo por favor ',
        	thisIsTraining: 'Esto es entrenamiento. Seleccione la “Tarea para avanzar”  y vuelva a intentarlo.',
        	youHaveCompleted: ', Has completado está unidad',
        	yourScore: 'Tu calificación',
        	nextUnit: 'Próxima unidad',
        	tryAgain: 'Inténtalo de nuevo',
        	velocity: 'Velocidad',
        	nextQuestion: 'Próxima operación',
        	selectAudibleSpeed: 'Velocidad de dictado',
        	slow: 'LENTO',
        	fast: 'RAPIDO',
        	start: 'INICIAR',
        	selectFlashSpeed: 'Selecciona la velocidad',
			writeDownYourAnswer: 'ESCRIBE TU RESPUESTA',
			typeHere: 'ESCRIBELA AQUI',
			beReady: 'LISTO',
			yourProgressAtFastmind: "Tu progreso en Fastmind",
			balance: "acumulados",
			continueTheAdventure: "¡CONTINÚA TU AVENTURA!",
			missions: "MISIONES",
			missionsDes: "¡Continúa con los ejercicios y acumula puntos!",
			yourProgress: "TUS AVANCES",
			yourProgressDes: "Revisa tus avances a lo largo de las misiones asignadas",
			hallOfFameDes: "Revisa tu lugar en el salón de la fama",
			continue: "CONTINUAR",
			watchResults: "VER RESULTADOS",
			watchRankings: "VER RANKING",
			focusTest: "Reto de concentración",
			yourFindNumber: "Tienes que buscar el número",
			instructions: "Instrucciones",
			focusIns1: "Da clic al ",
			focusIns2: "número ",
			focusIns3: "en cada fila lo más rápido que puedas. Tendrás 3 minutos para completar el reto, al terminar el reto da clic en el botón ",
			focusIns4: "",
			finish: "¡Terminé!",
			results: "Resultados",
			digitsSeen: "Dígitos vistos",
			linesSeen: "Línea vistas",
			selectedDigits: "Dígitos seleccionados",
			successes: "Aciertos",
			mistakes: "Errores",
			misses: "Ignoradas",
			sppeedAttention: "Velocidad de atención",
			mentalProductivity: "Productividad mental",
			levelConcentration: "Porcentaje de concentración",
			elapsedTime: "Tiempo de prueba",
			nextLesson: "Tu próxima sección es",
			championshipDes1: "Puedes inscribirte a un ",
			championshipDes2: "campeonato internacional",
			championshipDes3: "cuando termines el",
			certificationDes1: "Puedes obtener un",
			certificationDes2: "campeonato internacional",
			certificationDes3: "cuando termines el",
			daily: "Diario",
			week: "De la semana",
			month: "Del mes",
			year: "Del año",
			name: "Nombre",
			school: "Escuela",
			program: "Programa",
    },
    en: {
			chooseYourLanguage: 'Choose your language',
	        login: 'Log in',
	        startLeaning: 'Start Learning',
	        myProfile: 'My Profile',
	        focus: 'Focus',
	        smartbooks: 'Smartbooks',
	        logOut: 'Log out',
        	pleaseWait: 'Please wait...',
        	pleaseWaitLoading: 'Please Wait. Loading...',
        	mentalDevelopment: 'Mental development for your children',
        	investInYour: "Invest in your child's potential now",
        	register: 'Register',
        	haveAlready: 'Have already an account? ',
        	howmuchDoes: 'How much does it cost?',
        	basic: 'BASIC',
        	freeLevels: '12 free levels',
        	homeworksPerLevel: '10 homeworks per level',
        	groupLessons: 'group lessons',
        	perMonth: 'per month',
        	tryNow: 'TRY NOW',
        	premium: 'PREMIUM',
        	additionalLevels: '+10 additional levels',
        	moreHomework: '+10 more homework',
        	customProfilePic: '+10 custom profile pic',
        	structureOfProgram: 'Structure of the program',
        	enrollmentInOurProgram: 'Enrollment in our program does not require any specific qualification or skill, only your child must be between 5 and 14 years old.',
        	age: 'Age',
        	packedWithDesign: 'Packed with design features you already love plus unique',
        	learnMore: 'Learn more',
        	inviteYourFriends: 'Invite your friends',
        	sent: 'Sent',
        	submit: 'Submit',
        	username: 'Username',
        	password: 'Password',
        	forgotPassword: 'Forgot Password',
        	resetYourPassword: 'Reset your Password',
        	newPassword: 'New Password',
        	confirmPassword: 'Confirm Password',
        	dashboard: 'Dashboard',
        	accountSettings: 'Account Settings',
        	membership: 'Membership',
        	messages: 'Messages',
        	hallOfFame: 'Hall of fame',
        	userProfile: 'User Profile',
        	ageBracket: 'Age Bracket',
        	grade: 'Grade',
        	country: 'Country',
        	city: 'City',
        	parentsName: "Parent's name",
        	phone: 'Phone',
        	email: 'Email',
        	timeToStart: 'Time to start!',
        	welcome: 'Welcome',
        	friend: 'friend',
        	levels: 'Levels',
        	classroom: 'Classroom',
        	homework: 'Homework',
        	matchRoom: 'Match room',
        	nextClasses: 'Next classes',
        	ratingOfPlayers: 'Rating of players',
        	showBy: 'SHOW BY',
        	interCupFrom: 'You can enroll to an international cup from',
        	interCertiFrom: 'You can get an international certificate from',
        	level: 'Level',
        	todayPoints: 'Today your points',
        	todaySteps: 'Today completed steps',
        	completedStep: 'Completed step',
        	nextStepIs: 'Next step is',
        	day: 'Day',
        	youAreHere: 'You are here',
        	finishedAt: 'Finished at',
        	pending: 'Pending',
        	youAreAt: 'You are at',
        	yourClassEnded: 'Your Class has Ended',
        	lessonEnded: 'This lesson is ended.',
        	lessonStarted: 'This lesson is started.',
        	lessonStartedSoon: 'This lesson will be started soon.',
        	lessonNotStarted: 'This lesson is not started yet. Please wait!',
        	joinVideoMeeting: 'Join Video Meeting',
        	close: 'Close',
        	levelPoints: 'This level points is:',
        	lesson: 'Lesson',
        	of: 'of',
        	complete: 'complete',
        	static: 'Static',
        	audible: 'Audible',
        	flash: 'Flash',
        	rememberChoose: 'REMEMBER CHOOSE',
        	traningToPractice: 'Training to practice',
        	testToLevel: 'Test to advance level',
        	listenAndAnswer: 'Listen and Answer',
        	contragulations: 'Congratulations',
        	failed: 'Failed',
        	tryAgainPlease: 'Try again, please',
        	thisIsTraining: 'This is training. Please select testing and try again.',
        	youHaveCompleted: 'You have completed this unit',
        	yourScore: 'Your score',
        	nextUnit: 'Next Unit',
        	tryAgain: 'Try again',
        	velocity: 'Velocity',
        	nextQuestion: 'Next Questions',
        	selectAudibleSpeed: 'Selet Audible Speed',
        	slow: 'SLOW',
        	fast: 'FAST',
        	start: 'Start',
        	selectFlashSpeed: 'Selet Flash Speed',
			writeDownYourAnswer: 'Write down your answer',
			typeHere: 'Type here',
			beReady: 'Be ready',
			yourProgressAtFastmind: "Your progress at Fastmind",
			balance: "balance",
			continueTheAdventure: "Continue the adventure!",
			missions: "Missions",
			missionsDes: "Continue with your exercises and earn coins",
			yourProgress: "Your progress",
			yourProgressDes: "Review your progress throughout the assigned missions",
			hallOfFameDes: "Check your place in the hall of fame",
			continue: "Continue",
			watchResults: "Watch results",
			watchRankings: "Watch rankings",
			focusTest: "Focus test",
			yourFindNumber: "You need to find the number",
			instructions: "Instructions",
			focusIns1: "Click the ",
			focusIns2: "number ",
			focusIns3: "in each row as quickly as you can. You will have 3 minutes to complete the challenge, when you finish the challenge click on the ",
			focusIns4: "button",
			finish: "Finish",
			results: "Results",
			digitsSeen: "Digits seen",
			linesSeen: "Lines seen",
			selectedDigits: "Selected digits",
			successes: "Successes",
			mistakes: "Mistakes",
			misses: "Misses",
			sppeedAttention: "Speed of attention",
			mentalProductivity: "Mental productivity",
			levelConcentration: "Level of concentration",
			elapsedTime: "Elapsed time",
			nextLesson: "Next Lesson",
			championshipDes1: "You can sign up for an ",
			championshipDes2: "international championship",
			championshipDes3: "when you reach",
			certificationDes1: "You can sign up for an",
			certificationDes2: "international certification",
			certificationDes3: "when you reach",
			daily: "DAILY",
			week: "WEEK",
			month: "MONTH",
			year: "YEAR",
			name: "Name",
			school: "School",
			program: "Program",
    },
  };